// export const JsonTemplate = [
//   {
//     id: 1,
//     moduleId: 1,
//     component: "Dashboard",
//     name: "Dashboard",
//     displayname: "",
//     is_create: 4,
//     is_view: 0,
//     is_edit: 4,
//     parentId: null,
//     isDefault: false,
//     status: false,
//   },
//   {
//     id: 2,
//     moduleId: 2,
//     component: "Tenant Master",
//     name: "Tenant Master",
//     displayname: "Add Tenant",
//     is_create: 0,
//     is_view: 0,
//     is_edit: 0,
//     parentId: null,
//     isDefault: false,
//     status: false,
//     subcategories: [
//       {
//         SubModuleTypeID: 1,
//         component: "Tenancy Request",
//         name: "Tenancy Request",
//         is_create: 4,
//         is_view: 0,
//         is_edit: 0,
//         parentId: 2,
//         status: false,
//       },
//       {
//         SubModuleTypeID: 2,
//         component: "Current Agreement",
//         name: "Current Agreement",
//         is_create: 0,
//         is_view: 0,
//         is_edit: 0,
//         parentId: 2,
//         status: false,
//       },
//       {
//         SubModuleTypeID: 3,
//         component: "Agreement Expiring in 30 Days",
//         name: "Agreement Expiring in 30 Days",
//         is_create: 0,
//         is_view: 0,
//         is_edit: 4,
//         parentId: 2,
//         status: false,
//       },
//       {
//         SubModuleTypeID: 4,
//         component: "Expired Agreements",
//         name: "Expired Agreements",
//         is_create: 4,
//         is_view: 0,
//         is_edit: 4,
//         parentId: 2,
//         status: false,
//       },
//       {
//         SubModuleTypeID: 5,
//         component: "Agreement Renewal Requests",
//         name: "Agreement Renewal Requests",
//         is_create: 4,
//         is_view: 0,
//         is_edit: 0,
//         parentId: 2,
//         status: false,
//       },
//       {
//         SubModuleTypeID: 6,
//         component: "Negotiation",
//         name: "Negotiation",
//         is_create: 4,
//         is_view: 0,
//         is_edit: 0,
//         parentId: 2,
//         status: false,
//       },
//       {
//         SubModuleTypeID: 7,
//         component: "Terminated Agreement",
//         name: "Terminated Agreement",
//         is_create: 4,
//         is_view: 0,
//         is_edit: 4,
//         parentId: 2,
//         status: false,
//       },
//     ],
//   },
//   {
//     id: 3,
//     moduleId: 3,
//     component: "RentCollection",
//     name: "Rent Collection",
//     displayname: "Add Rent",
//     is_create: 0,
//     is_view: 0,
//     is_edit: 0,
//     parentId: null,
//     isDefault: false,
//     status: false,
//   },
//   {
//     id: 4,
//     moduleId: 4,
//     component: "Property Master",
//     name: "Property Master",
//     displayname: "Add Building",
//     is_create: 0,
//     is_view: 0,
//     is_edit: 0,
//     parentId: null,
//     isDefault: false,
//     status: false,
//   },
//   {
//     id: 5,
//     moduleId: 5,
//     component: "Expense Management",
//     name: "Expense Management",
//     displayname: "Add Expense",
//     is_create: 0,
//     is_view: 0,
//     is_edit: 0,
//     parentId: null,
//     isDefault: false,
//     status: false,
//   },
//   {
//     id: 6,
//     moduleId: 6,
//     component: "Maintenance Requests",
//     name: "Maintenance Requests",
//     displayname: "Maintenance Request",
//     is_create: 0,
//     is_view: 0,
//     is_edit: 0,
//     parentId: null,
//     isDefault: false,
//     status: false,
//   },
// ];


// PermissionsJsonTemplate.js
const JsonTemplate = [
  {
    id: 1,
    moduleId: 1,
    component: "Dashboard",
    name: "Dashboard",
    displayname: "",
    is_create: 4,
    is_view: 0,
    is_edit: 4,
    parentId: null,
    isDefault: false,
    status: false,
  },
  {
        id: 2,
        moduleId: 2,
        component: "Tenant Master",
        name: "Tenant Master",
        displayname: "Add Tenant",
        is_create: 0,
        is_view: 0,
        is_edit: 0,
        parentId: null,
        isDefault: false,
        status: false,
        subcategories: [
          {
            SubModuleTypeID: 1,
            component: "Tenancy Request",
            name: "Tenancy Request",
            is_create: 4,
            is_view: 0,
            is_edit: 0,
            parentId: 2,
            status: false,
          },
          {
            SubModuleTypeID: 2,
            component: "Current Agreement",
            name: "Current Agreement",
            is_create: 0,
            is_view: 0,
            is_edit: 0,
            parentId: 2,
            status: false,
          },
          {
            SubModuleTypeID: 3,
            component: "Agreement Expiring in 30 Days",
            name: "Agreement Expiring in 30 Days",
            is_create: 0,
            is_view: 0,
            is_edit: 4,
            parentId: 2,
            status: false,
          },
          {
            SubModuleTypeID: 4,
            component: "Expired Agreements",
            name: "Expired Agreements",
            is_create: 4,
            is_view: 0,
            is_edit: 4,
            parentId: 2,
            status: false,
          },
          {
            SubModuleTypeID: 5,
            component: "Agreement Renewal Requests",
            name: "Agreement Renewal Requests",
            is_create: 4,
            is_view: 0,
            is_edit: 0,
            parentId: 2,
            status: false,
          },
          {
            SubModuleTypeID: 6,
            component: "Negotiation",
            name: "Negotiation",
            is_create: 4,
            is_view: 0,
            is_edit: 0,
            parentId: 2,
            status: false,
          },
          {
            SubModuleTypeID: 7,
            component: "Terminated Agreement",
            name: "Terminated Agreement",
            is_create: 4,
            is_view: 0,
            is_edit: 4,
            parentId: 2,
            status: false,
          },
        ],
      },
  {
    id: 3,
    moduleId: 3,
    component: "RentCollection",
    name: "Rent Collection",
    displayname: "Add Rent",
    is_create: 0,
    is_view: 0,
    is_edit: 0,
    parentId: null,
    isDefault: false,
    status: false,
  },
  {
    id: 4,
    moduleId: 4,
    component: "Property Master",
    name: "Property Master",
    displayname: "Add Building",
    is_create: 0,
    is_view: 0,
    is_edit: 0,
    parentId: null,
    isDefault: false,
    status: false,
  },
  {
    id: 5,
    moduleId: 5,
    component: "Expense Management",
    name: "Expense Management",
    displayname: "Add Expense",
    is_create: 0,
    is_view: 0,
    is_edit: 0,
    parentId: null,
    isDefault: false,
    status: false,
  },
  {
    id: 6,
    moduleId: 6,
    component: "Maintenance Requests",
    name: "Maintenance Requests",
    displayname: "Maintenance Request",
    is_create: 0,
    is_view: 0,
    is_edit: 0,
    parentId: null,
    isDefault: false,
    status: false,
  },
];

export default JsonTemplate;
