import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { all_routes } from '../../router/all_routes';
import CollapseHeader from '../../core/common/collapse-header';
import { PMSDashBoardApi } from '../../Services/DashboardServices';
import { useDispatch, useSelector } from 'react-redux';

const Dashboard = () => {
    const route = all_routes;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
   

    const location = useLocation();
    const isLinkActive = (route) => {
        return location.pathname === route;
    };

    useEffect(() => {
        const PMSDashBoard = async () => {
            setLoading(true);
            try {
                const reqData = await PMSDashBoardApi();
                const resData = reqData.data;
                setData(resData.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        };
        PMSDashBoard();
    }, []);

    const iconMapping = {
        "Total Building": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Properties.svg",
        "Units": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Units.svg",
        "Tenants": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Tenant.svg",
        "Income": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg",
        "Expense": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Expense.svg",
        "Net Income": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.Amount.svg",
        "Staff": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/Customers.svg",
        "Security Group": "https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.securitygroup.svg",
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <h4 className="page-title">
                                            PMS <span className="count-title">Dashboard</span>
                                        </h4>
                                    </div>
                                    <div className="col-8 text-end">
                                        <div className="head-icons">
                                            {/* <CollapseHeader /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="row">
                                        {loading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            data.map((item, index) => (
                                                <div key={index} className="col-xl-3 col-lg-6">
                                                    <div className={`campaign-box bg-${index % 4 === 0 ? 'danger' : index % 4 === 1 ? 'warning' : index % 4 === 2 ? 'purple' : 'success'}-light`}>
                                                        <div className="campaign-img">
                                                            <span>
                                                                <img src={iconMapping[item.name] || "ti ti-brand-campaignmonitor"} />
                                                            </span>
                                                            <p>{item.name}</p>
                                                        </div>
                                                        <h2>{item.totalCount}</h2>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
