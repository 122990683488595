import React, { useState, useEffect } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import JsonTemplate from "../../Services/PermissionsJsonTemplate";
import { AddPermissions, AssignedPermissions, UpdateSecurityGroupPermissions } from "../../Services/PermissionsServices";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import "../../Style/AddStaffRights.css";

const AddStaffRights = ({ securityGroupobject,ispermissionpopup  }) => {
  
  const location = useLocation(); 
  const navigate = useNavigate();
  // const { selectedSecurityGroup } = location.state;
  const [formData, setFormData] = useState(JsonTemplate);
  const [defaultIndex, setDefaultIndex] = useState(null);
  const [loader, setloader] = useState(false);
  const [IsViewCheckedsubindex, setIsViewCheckedsubindex] = useState(false);

  const [display, Setdisplay] = useState(false);
  const [isViewCheckedInitially, setIsViewCheckedInitially] = useState(false);
  useEffect(() => {
  if (ispermissionpopup && securityGroupobject.securityGroupID !== 0) {

    AssignedPermissions(securityGroupobject.securityGroupID).then((resData) => {
      if (resData) {
        {
          if (resData.data != null) {
            Setdisplay(true);
            const jsonObject = JSON.parse(resData.data);
            const defaultIndex = jsonObject.findIndex((item) => item.isDefault == true);
            // jsonObject.forEach((item) => {
            //   
            //   if (item.is_create === 1 || item.is_edit === 3) {
            //     item.is_viewDisabled = true;
            //     if(item.is_create === 1){
            //       item.is_createDisabled = true;
            //     }
            //     else if (item.is_edit ==3){
                  
            //     item.is_editDisabled = true;
            //     }
           
            //   }
            //   if (item.subcategories) {
            //     const anySubCreateChecked = item.subcategories.some(subItem => subItem.is_create === 1 || subItem.is_edit === 3);
            //     if (anySubCreateChecked) {
            //       if(item.is_create ==1){
            //         item.is_createDisabled = true;
            //       }
            //       else if(item.is_edit == 3){
            //         item.is_editDisabled = true;
            //       }
                 
                 
            //       item.subcategories.forEach(subItem => {
            //         if (subItem.is_create === 1 || subItem.is_edit === 3) {
            //           subItem.is_viewDisabled = true;
            //         }
            //       });
            //     } else {
            //       item.is_createDisabled = false;
            //       item.is_editDisabled = false;
            //     }
            //   }
            // });
            setDefaultIndex(defaultIndex);
            setFormData(jsonObject);
            
          } else {
            setFormData(
              JsonTemplate.map((item) => ({
                ...item,
                is_create: item.is_create,
                is_view: item.is_view,
                is_edit: item.is_edit,
                subcategories: item.subcategories
                  ? item.subcategories.map((subItem) => ({
                      ...subItem,
                      is_create: subItem.is_create,
                      is_view: subItem.is_view,
                      is_edit: subItem.is_edit,
                    }))
                  : [],
              }))
            );
            Setdisplay(false);
            setDefaultIndex(null);
          }
        }
      }
    });
    
    

    
    }
  }, [ispermissionpopup, securityGroupobject.securityGroupID]);
    

  const handleCheckboxChange = (index, subIndex) => {
    
    
    
    
    const updatedFormData = [...formData];
    if (subIndex === undefined) {
      updatedFormData[index].is_view = updatedFormData[index].is_view === 2 ? 0 : 2;
      updatedFormData[index].is_viewDisabled = false; 
      setIsViewCheckedInitially(updatedFormData[index].is_view === 2);
    } 
    else {
      updatedFormData[index].subcategories[subIndex].is_view = updatedFormData[index].subcategories[subIndex].is_view === 2 ? 0 : 2;
      updatedFormData[index].subcategories[subIndex].is_viewDisabled = false; 
      setIsViewCheckedsubindex(updatedFormData[index].subcategories[subIndex].is_view  ===2)
      
      const isAnySubcategoryChecked = updatedFormData[index].subcategories.some(subcategory => subcategory.is_view === 2);
if (subIndex !== undefined || subIndex !==0)
      if (isAnySubcategoryChecked) {
        updatedFormData[index].is_view = 2;
        updatedFormData[index].is_viewDisabled = true; 
        
        setIsViewCheckedInitially(true);
      } else {
        
        const isAnyParentcreateEdit = updatedFormData[index].is_create === 1 || updatedFormData[index].is_edit === 3;

        if(!isAnyParentcreateEdit){
        updatedFormData[index].is_view = 0;
        updatedFormData[index].is_viewDisabled = false; }
        setIsViewCheckedInitially(false);
      }
    }
    updateStatus(updatedFormData[index],  updatedFormData[index].subcategories[subIndex]);
    setFormData(updatedFormData);
    checkDefaultState(index);
  };


  const handleCheckboxChangeEdit = (index, subIndex) => {
    const updatedFormData = [...formData];
  
    const setParentPermissions = (parentIndex) => {
      const parentCategory = updatedFormData[parentIndex];
      if (parentCategory.is_edit === 0) {
        parentCategory.is_edit = 3;
        parentCategory.is_view = 2;
        parentCategory.is_viewDisabled = true;
      }
    };
  
    if (subIndex === undefined) {
      const isEditChecked = updatedFormData[index].is_edit === 3;
      updatedFormData[index].is_edit = isEditChecked ? 0 : 3;
      if (!isEditChecked) {
        updatedFormData[index].is_view = 2;
        updatedFormData[index].is_viewDisabled = true;
      } 
      else if (updatedFormData[index].is_create === 0) {
        const isAnySubcategoryChecked = updatedFormData[index].subcategories.some(subcategory => subcategory.is_view === 2);
        if(!isAnySubcategoryChecked){
          updatedFormData[index].is_view = 0;
          updatedFormData[index].is_viewDisabled = false;
        }
      
      }
      if (isViewCheckedInitially) {
        updatedFormData[index].is_view = 2;
      }
    } else {
      const isEditChecked = updatedFormData[index].subcategories[subIndex].is_edit === 3;
      updatedFormData[index].subcategories[subIndex].is_edit = isEditChecked ? 0 : 3;
  
      if (!isEditChecked) {
        updatedFormData[index].subcategories[subIndex].is_view = 2;
        updatedFormData[index].subcategories[subIndex].is_viewDisabled = true;
        setParentPermissions(index);
      } else if (updatedFormData[index].subcategories[subIndex].is_create === 0 || updatedFormData[index].subcategories[subIndex].is_create === 4) {
        updatedFormData[index].subcategories[subIndex].is_view = 0;
        updatedFormData[index].subcategories[subIndex].is_viewDisabled = false;
      }
      if (IsViewCheckedsubindex) {
        updatedFormData[index].subcategories[subIndex].is_view = 2;
      }

      const anySubCreateChecked = updatedFormData[index].subcategories.some(subItem => subItem.is_edit === 3);
      if (anySubCreateChecked) {
        updatedFormData[index].is_editDisabled = true;
        updatedFormData[index].is_viewDisabled = true;
      } else {
        updatedFormData[index].is_editDisabled = false;
        // updatedFormData[index].is_viewDisabled = false;
      }


    }
  
    updateStatus(updatedFormData[index], updatedFormData[index].subcategories[subIndex]);
    setFormData(updatedFormData);
    checkDefaultState(index);
};




  const handleCheckboxChangecreate = (index, subIndex) => {
    
    
    const updatedFormData = [...formData];
  
    const setParentPermissions = (parentIndex) => {
      
      const parentCategory = updatedFormData[parentIndex];
      if (parentCategory.is_create === 0) {
        parentCategory.is_create = 1;
        parentCategory.is_view = 2;
        parentCategory.is_viewDisabled = true;
      }
    };
  
    if (subIndex === undefined) {
      const isCreateChecked = updatedFormData[index].is_create === 1;
      updatedFormData[index].is_create = isCreateChecked ? 0 : 1;
      if (!isCreateChecked) {
        updatedFormData[index].is_view = 2;
        updatedFormData[index].is_viewDisabled = true;
      } 
      else if (updatedFormData[index].is_edit === 0) {
        const isAnySubcategoryChecked = updatedFormData[index].subcategories.some(subcategory => subcategory.is_view === 2);
        if(!isAnySubcategoryChecked){
          updatedFormData[index].is_view = 0;
          updatedFormData[index].is_viewDisabled = false;
        }
      
      }
      if (isViewCheckedInitially) {
        updatedFormData[index].is_view = 2;
      }
    } else {
      const isCreateChecked = updatedFormData[index].subcategories[subIndex].is_create === 1;
      updatedFormData[index].subcategories[subIndex].is_create = isCreateChecked ? 0 : 1;
  
      if (!isCreateChecked) {
        updatedFormData[index].subcategories[subIndex].is_view = 2;
        updatedFormData[index].subcategories[subIndex].is_viewDisabled = true;
        setParentPermissions(index);
      } 
      else if (
        updatedFormData[index].subcategories[subIndex].is_edit === 0 || updatedFormData[index].subcategories[subIndex].is_edit === 4) {
        updatedFormData[index].subcategories[subIndex].is_view = 0;
        updatedFormData[index].subcategories[subIndex].is_viewDisabled = false;
      }
      if (IsViewCheckedsubindex) {
        updatedFormData[index].subcategories[subIndex].is_view = 2;
      }
  
      // Disable parent create and view checkboxes if any subcategory create is checked
      const anySubCreateChecked = updatedFormData[index].subcategories.some(subItem => subItem.is_create === 1);
      if (anySubCreateChecked) {
        updatedFormData[index].is_createDisabled = true;
        updatedFormData[index].is_viewDisabled = true;
      } else {
        updatedFormData[index].is_createDisabled = false;
        // updatedFormData[index].is_viewDisabled = false;
      }
    }
  
    updateStatus(updatedFormData[index], updatedFormData[index].subcategories[subIndex]);
    setFormData(updatedFormData);
    checkDefaultState(index);
  };
  


  
  const handleMarkAsDefault = (index) => {
    
    
    setDefaultIndex(index);
  };
  const updateStatus = (item,subitem) => {
    
    
    
    const anyChecked =
      item.is_create === 1 || item.is_view === 2 || item.is_edit === 3;
    item.status = anyChecked;
    if (subitem){
      const anyChecked =
      subitem.is_create === 1 || subitem.is_view === 2 || subitem.is_edit === 3;
      subitem.status = anyChecked;
    }
  };

  const handleSubmit = (e) => {
    
    
    e.preventDefault();

    const updatedFormData = formData.map((item, index) => {
      return {
        ...item,

        isDefault: index === defaultIndex,
        subcategories: item.subcategories
          ? item.subcategories.map((subItem, subIndex) => ({
              ...subItem,
            }))
          : [],
      };
    });
    const updatedFormDataWithGroupId = {
      securityGroupId: securityGroupobject.securityGroupID,
      permissionRequests: updatedFormData,
      environmentInd:true
    };
    

    if (display) {
      
      
      setloader(true);
      UpdateSecurityGroupPermissions(securityGroupobject.securityGroupID,updatedFormDataWithGroupId)
        .then(() => {
          toastr.success("Permissions updated successfully!");
          setloader(false);
        })
        .catch((error) => {
          toastr.error("Failed to update permissions.");
          console.error("Update error:", error);
        });
    } else {
      setloader(true);
      AddPermissions(updatedFormDataWithGroupId)
        .then(() => {
          toastr.success("Permissions assigned successfully!");
          Setdisplay(true);
          setloader(false);
        })
        
        .catch((error) => {
          toastr.error("Failed to assign permissions.");
          console.error("Assignment error:", error);
        });
    }
  };
  const checkDefaultState = (index) => {
    
    
    // const anyCheckboxChecked = formData.some(
    //   (item) => item.is_create === 1 || item.is_view === 2 || item.is_edit === 3
    // );
    const anyCheckboxChecked = formData[index].is_create === 1 || formData[index].is_view === 2 || formData[index].is_edit === 3;

    if (!anyCheckboxChecked) {
      setDefaultIndex(null);
    }
    
  };
  const isAnyCheckboxChecked = (item) => {
    const anyChecked = item.is_create === 1 || item.is_view === 2 || item.is_edit === 3;
   
    return anyChecked ;
  };


  return (
    <>
      <div className="d-flex align-items-center">
        <div className="ms-3">

        
          <h3>{securityGroupobject.securityGroupName}</h3>
          <p className="staff-txt mb-1 text-capitalize">
            {" "}
            {securityGroupobject.securityGroupID}
          </p>
        </div>
      </div>

      <div className="card border-0">
        <div className="card-body">
          <div className="table-responsive">
            {/* <h2>Add Staff Rights</h2> */}
            <form onSubmit={handleSubmit} >
              <table className="table mb-4" >
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th className="headerAdd text-center" scope="col">
                      Add/
                      <br />
                      Create
                    </th>
                    <th
                      className="headerview text-center align-content-center"
                      scope="col"
                    >
                      View
                    </th>
                    <th className="headerApprove text-center" scope="col">
                      Approve/
                      <br />
                      Update
                /  Reject
                    </th>
                  </tr>
                  <tr className="bg-secondary">
                    <th scope="col" className="text-nowrap">
                      Group permissions
                    </th>
                    <th scope="col"></th>
                    <th scope="col" className="text-center">
                      {/* <input
                        type="checkbox"
                        className="custom-checkbox"
                        onChange={(e) =>
                          handleHeaderCheckboxChange(e.target.checked, 1)
                        }
                      /> */}
                    </th>
                    <th scope="col" className="text-center">
                      {/* <input
                        type="checkbox"
                        onChange={(e) =>
                          handleHeaderCheckboxChange(e.target.checked, 2)
                        }
                      /> */}
                    </th>
                    <th scope="col" className="text-center">
                      {/* <input
                        type="checkbox"
                        onChange={(e) =>
                          handleHeaderCheckboxChange(e.target.checked, 3)
                        }
                      /> */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formData.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr>
                          <td className="border-0 wth">
                            <input
                              type="radio"
                              checked={defaultIndex === index}
                              onChange={() => handleMarkAsDefault(index)}
                              disabled={!isAnyCheckboxChecked(item)}
                            />
                            <span className="px-2">Mark as Default</span>
                          </td>

                        <td className="align-items-center bg-light border-0">
                          <div
                          // style={{
                          //   borderBottom: "2px solid black",
                          //   width: "10px",
                          // }}
                          ></div>
                          {item.name}
                        </td>
                        <td className="text-center">
                          {item.is_create === 4 ? (
                            <div className="circle circle-disabled"></div>
                          ) : (
                            <input
      type="checkbox"
      checked={item.is_create === 1}
      onChange={() => handleCheckboxChangecreate(index)}
      disabled={item.is_createDisabled}
    />
                          )}
                        </td>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            className="custom-checkbox"
                            checked={item.is_view === 2}
                            onChange={() => handleCheckboxChange(index)}
                            disabled={item.is_viewDisabled}
                          />
                        </td>
                        <td className="wth text-center">
                          {item.is_edit === 4 ? (
                            <div className="circle circle-disabled"></div>
                          ) : (
                            <input
                              type="checkbox"
                              checked={item.is_edit === 3}
                              onChange={() => handleCheckboxChangeEdit(index)}
                              disabled={item.is_editDisabled}
                            />
                          )}
                        </td>
                      </tr>
                      {item.subcategories &&
                        item.subcategories.map((subItem, subIndex) => (
                          <tr key={`${index}-${subIndex}`}>
                            <td className=" border-0"></td>
                            <td className="align-items-center d-flex border-0 px-5 text-center">
                              {" "}
                              <div
                                style={{
                                  borderBottom: "2px solid black",
                                  width: "10px",
                                }}
                              ></div>{" "}
                              {subItem.name}
                            </td>
                            <td className="text-center">
                              {subItem.is_create === 4 ? (
                                <div className="circle"></div>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={subItem.is_create === 1}
                                  onChange={() =>
                                    handleCheckboxChangecreate(index, subIndex)
                                  }
                                />
                              )}
                            </td>
                            <td className="text-center">
                              <input
                                type="checkbox"
                                checked={subItem.is_view === 2}
                                onChange={() =>
                                  handleCheckboxChange(index, subIndex)
                                  
                                }
                                disabled={subItem.is_viewDisabled}
                              />
                            </td>
                            <td className="text-center">
                              {subItem.is_edit === 4 ? (
                                <div className="circle"></div>
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={subItem.is_edit === 3}
                                  onChange={() =>
                                    handleCheckboxChangeEdit(index, subIndex)
                                  }
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              {display ? (
                <button
                  className="btn btn-primary float-end rounded-5"
                  type="submit"
                  disabled={loader ||defaultIndex === null}
                >
                  {loader ? (
                   <>
                     {" "}
                     <span className="spinner-border spinner-border-sm mr-1 "></span>
                   </>
                 ) : null}
                  Update Permissions
                </button>
              ) : null}

              {!display ? (
               <button
               className="btn btn-primary float-end rounded-5"
               type="submit"
               disabled={loader || defaultIndex === null}>
                   
                     {loader ? (
                   <>
                     {" "}
                     <span className="spinner-border spinner-border-sm mr-1 "></span>
                   </>
                 ) : null}
             
               Assign Permissions
             </button>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStaffRights;
