import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { all_routes } from '../../router/all_routes';
import CollapseHeader from '../../core/common/collapse-header';
import { PMSDashBoardApi } from '../../Services/DashboardServices';
import { useDispatch, useSelector } from 'react-redux';

const Profile = () => {
    const route = all_routes;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
   

    const location = useLocation();
    const isLinkActive = (route) => {
        return location.pathname === route;
    };


  

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    
                                    <div className="col-8 text-end">
                                        <div className="head-icons">
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card main-card">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-4">
                                        <h4 className="page-title">
                                            Comming <span className="count-title">soon</span>
                                        </h4>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;
