import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "./redux/Store";
import ALLRoutes from "./router/router";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import '../src/style/css/feather.css'
import '../src/style/icon/tabler-icons/webfont/tabler-icons.css'
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import '../src/style/icon/boxicons/boxicons/css/boxicons.min.css'
import '../src/index.scss'
import React from "react";
import Feature from "./feature-module/feature";
export default function Root(props) {
  return (
    <>
      <Provider store={store}>
      {/* <React.StrictMode> */}
      <Feature />
      
        
          {/* </React.StrictMode> */}
      </Provider>
    </>
  );
}
