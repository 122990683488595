import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Select, { StylesConfig } from 'react-select'
import { setActivityTogglePopup, setActivityTogglePopupTwo } from '../data/redux/commonSlice';
import CampaignComplete from '../../feature-module/campaign/securityGroups';
import SecurityGroupModal from '../../feature-module/campaign/SecurityGroupModal';

const CompaignComplete = ({staffObject,getAllStaffManagementapi}) => {
    const dispatch = useDispatch();
    const activityToggle = useSelector((state: any) => state?.activityTogglePopup);
    const activityToggleTwo = useSelector((state:any) => state?.activityTogglePopupTwo);

 
 
   
    return (
        <>
        
          
<div className="modal custom-modal fade" id="AssignSecurityGroup" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                  
                        <div className="modal-body">
                        <SecurityGroupModal staffObject={staffObject} getAllStaffManagementapi={getAllStaffManagementapi} />
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default CompaignComplete
