import React, { useState } from "react";
import ImageWithBasePath from "../../ImageWithBasePath";
import { Link,useNavigate } from "react-router-dom";
import { all_routes } from "../../router/all_routes";
import loginimg from "../../../public/assets/img/authentication/login.png";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
const Login = () => {
  const route = all_routes;
  const navigate = useNavigate();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [formData,setformdata] = useState({});
  const [loadinguser,setloadinguser]=useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  const styles = {
    backgroundImage: `url(${loginimg})`,
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',
  };
  const [logo, setlogo] = useState(
    "https://d2og5lryw1ajtt.cloudfront.net/img/mumtalikatilogred.png"
  );


  const handleChange = (e)=>{
    
    setformdata({
      ...formData,
      [e.target.id]:e.target.value,
    })
  
  }
  const handleSubmit = async (e) => {
    
  
    e.preventDefault();
    try {
      setloadinguser(true)

      const res = await fetch(
       `${process.env.REACT_APP_USER_BASE_URL}/Users/Authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "platform":"web",
          },
          body: JSON.stringify(formData),
        }
      );
      if (res.status == 204) {
        toastr.warning("User not found!");
      }
      const data = await res.json();
      const userId = data.id;
      localStorage.setItem("userId", userId);

      const userTypes = data.userTypes.map((userType) => userType.id);
      if (!userTypes.includes(1)){
toastr.warning("Access denied")
setloadinguser(false)
      }
      if (userTypes.includes(1)) {
        
        
        navigate("/admin/dashboard");
        localStorage.setItem("token", data.token);
        // dispatch(signInSuccess(data));
        setloadinguser(false)
      }

      if (data.success === false) {
        // dispatch(signInFailure(data.message));
        return;
      }
      
    } catch (error) {
      // dispatch(signInFailure(error.message));
      setloadinguser(false)
    }
  };


  return (
    <div className="account-content">
      <div className="login-wrapper account-bg  " style={styles}>
        <div className="login-content">
          <form style={{width:"40vw"}} onSubmit={handleSubmit}>
            <div className="login-user-info">
              <div className="login-logo">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Logo"
                />
              </div>
              <div className="login-heading">
                <h4>Sign In</h4>
                <p>Please login to continue to your account.</p>
              </div>
              <div className="form-wrap">
                <label className="col-form-label">Phone Number</label>
                <div className="form-wrap-icon">
                  <input type="text" className="form-control" 
                   id="userName"
                   onChange={handleChange}  />
                  <i className="ti ti-phone" />
                </div>
              </div>
              <div className="form-wrap">
                <label className="col-form-label">Password</label>
                <div className="pass-group">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    className="pass-input form-control"
                    id="password"
                    onChange={handleChange}
                  />
                  <span
                    className={`ti toggle-password ${
                      isPasswordVisible ? "ti-eye" : "ti-eye-off"
                    }`}
                    onClick={togglePasswordVisibility}
                  ></span>
                </div>
              </div>
              <div className="form-wrap form-wrap-checkbox">
                <div className="custom-control custom-checkbox">
                  {/* <label className="check">
                    <input type="checkbox" />
                    <span className="box" /> Keep me logged in
                  </label> */}
                </div>
                <div className="text-end">
                </div>
              </div>
              <div className="form-wrap">
              <button type="submit" className="btn btn-primary mumclr login-btn" 
                    disabled={loadinguser}
              >
                {loadinguser && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                      Sign In
                    </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Login;