import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
import { SidebarData } from "../json/sidebarData";
import ImageWithBasePath from "../ImageWithBasePath";
import { useDispatch, useSelector } from "react-redux";
import { setExpandMenu } from "../redux/Commonslice";
import { all_routes } from "../router/all_routes";

const Sidebar = () => {
  
  const location = useLocation();
  const expandMenu = useSelector((state: any) => state.expandMenu);
  const dispatch = useDispatch();

  const [subOpen, setSubopen] = useState("");
  const [subsidebar, setSubsidebar] = useState("");

  useEffect(() => {
    // Loop through SidebarData to find the active item and open its parent
    SidebarData.forEach((mainLabel) => {
      mainLabel.submenuItems.forEach((title: any) => {
        if (location.pathname.startsWith(title.link)) {
          setSubopen(mainLabel.label); // Open the parent
          setSubsidebar(title.label); // Open the submenu
        }
      });
    });
  }, [location.pathname]);

  const toggleSidebar = (title: any) => {
    setSubopen(subOpen === title ? "" : title);
  };

  const toggleSubsidebar = (subitem: any) => {
    setSubsidebar(subsidebar === subitem ? "" : subitem);
  };

  const toggle = () => {
    dispatch(setExpandMenu(expandMenu ? false : true));
  };

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                {SidebarData?.map((mainLabel, index) => (
                  <li className="clinicdropdown" key={index}>
                    <h6 className="submenu-hdr">{mainLabel?.label}</h6>
                    <ul>
                      {mainLabel?.submenuItems?.map((title: any, i) => {
                        return (
                          <li className="submenu" key={i}>
                            <Link
                              to={title?.link === all_routes.leadsDashboard ? "/jaganb" : title?.link}
                              onClick={() => toggleSidebar(title?.label)}
                              className={`${subOpen === title?.label ? "subdrop active" : ""} ${location.pathname.startsWith(title.link) ? "active" : ""}`}
                            >
                              <i className={title.icon}></i>
                              <span>{title?.label}</span>
                            </Link>
                            <ul
                              style={{
                                display: subOpen === title?.label ? "block" : "none",
                              }}
                            >
                              {title?.submenuItems?.map((item: any, titleIndex: any) => (
                                <li className="submenu submenu-two" key={titleIndex}>
                                  <Link
                                    to={item?.link}
                                    className={`${subsidebar === item?.label ? "active" : ""}`}
                                    onClick={() => toggleSubsidebar(item?.label)}
                                  >
                                    {item?.label}
                                  </Link>
                                  <ul
                                    style={{
                                      display: subsidebar === item?.label ? "block" : "none",
                                    }}
                                  >
                                    {item?.submenuItems?.map((subItem: any, subIndex: any) => (
                                      <li key={subIndex}>
                                        <Link
                                          to={subItem?.link}
                                          className={`${subItem?.link === location.pathname ? "active" : ""}`}
                                        >
                                          {subItem?.label}
                                        </Link>
                                      </li>
                                    ))}
                                  </ul>
                                </li>
                              ))}
                            </ul>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default Sidebar;
