// import LoginAcc from "../feature-module/auth/LoginAcc";
import EmailVerification from "../feature-module/auth/emailVerification";
import ForgotPassword from "../feature-module/auth/forgotPassword";
import Login from "../feature-module/auth/login";
import Register from "../feature-module/auth/register";
import ResetPassword from "../feature-module/auth/resetPassword";
import Campaign from "../feature-module/campaign/staff";
import CampaignComplete from "../feature-module/campaign/securityGroups";
import Staffmanagement from "../feature-module/campaign/staffmanagement";
import { all_routes } from "./all_routes";
import Dashboard from "../feature-module/campaign/dashboard";
import Profile from "../feature-module/campaign/profile";


export const publicRoutes = [
  // {
  //   path: all_routes.campaign,
  //   element: <Campaign />,
  // },
  // {
  //   path: all_routes.campaignComplete,
  //   element: <CampaignComplete />,
  // },
  {
    path: all_routes.staffmanagement,
    element: <Staffmanagement />,
  },
  {
    path: all_routes.dashboard,
    element: <Dashboard />,
  },
  {
    path: all_routes.userProfile,
    element: <Profile />,
  },
];

export const authRoutes = [
  {
    path: all_routes.login,
    element: <Login />,
  },
  // {
  //   path: all_routes.register,
  //   element: <Register />,
  // },
  // {
  //   path: all_routes.emailVerification,
  //   element: <EmailVerification />,
  // },
  // {
  //   path: all_routes.resetPassword,
  //   element: <ResetPassword />,
  // },
  // {
  //   path: all_routes.forgotPassword,
  //   element: <ForgotPassword />,
  // },
];
