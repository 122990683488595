import api from "../Interceptor";

export const AddPermissions = async (postData) => {
  try {
    const response = await api.post(
      "/Permission/AssignSecurityGroupPermissions",
      postData
    );
    if (response.status === 200) {
    
    
    } else {
      console.error("Failed to post staff data:", response.statusText);
    }
    return response.data;
  } catch (error) {
    console.error("Error posting staff data:", error);
  }
};

export const AssignedPermissions = async (securityGroupID) => {
  try {
    
    
    const response = await api.post(
      "/Permission/PermissionJsonBySecurityGroup",
      {
        securityGroupId: securityGroupID,
        environmentInd:true
      }
    );
    if (response.status === 200) {
      
      
    } else {
      console.error("Failed to Get permissions data:", response.statusText);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting permission data:", error);
  }
};




export const UpdateSecurityGroupPermissions = async (SecurityGroupId,postrequest) => {
  try {
    
    const response = await api.put(
      `/Permission/UpdateSecurityGroupPermissions?SecurityGroupId=${SecurityGroupId}`,
      postrequest
     
    );
    if (response.status === 200) {
     

    } else {
      console.error("Failed to Get permissions data:", response.statusText);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting permission data:", error);
  }
};
