import React, { useState, useEffect } from "react";
import { Link, useLocation,useNavigate } from 'react-router-dom'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import CompaignComplete from '../../core/modals/compaignComplete_modal';
import { compaignData } from '../../json/campaignData';
import { Table } from "react-bootstrap";
import { TableData } from '../../core/data/interface';
import { all_routes } from '../../router/all_routes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setActivityTogglePopupTwo } from '../../redux/Commonslice';
import { useDispatch, useSelector } from 'react-redux';
import CollapseHeader from '../../core/common/collapse-header';
import CampaignModal from "../../core/modals/compaignComplete_modal";
import { getAllStaffManagement } from "../../Services/StaffServices";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import { AllSecurityGroups, createSecurityGroup } from "../../Services/SecurityGroupServices";
import Chip from "@mui/material/Chip";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import AddStaffRights from "./AddStaffRights";


import JsonTemplate from "../../Services/PermissionsJsonTemplate";
import { AddPermissions, AssignedPermissions, UpdateSecurityGroupPermissions } from "../../Services/PermissionsServices";



const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background: "#E41F07 !important",
    },
  },
}));



interface SecurityGroupModel{
    securityGroupID:number;
    securityGroupName:string;
}

const AddSecurityGroup = ({ toggleCreateSecurityGroupForm, refreshData }) => {
  
  const navigate = useNavigate();
  const [securityGroupformData, setsecurityGroupformData] = useState({
    name: "",
    desc: "",
    status: false,
  });
  const UserID = localStorage.getItem("userId");
  const classes = useStyles();
 
  const [IsSecurityGroupFormOpen, setIsSecurityGroupFormOpen] = useState(false);
  const [ispermissionpopup, setPermissionpopup] = useState(false);
  const [securityGroupobject, setsecurityGroupobject] = useState<SecurityGroupModel>({
    securityGroupID: 0,
    securityGroupName: ''
  });
  const [loader, setloader] = useState(false);

  const handleChange = (e) => {
    
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
      setsecurityGroupformData({ ...securityGroupformData, [e.target.id]: value });
  };

  const handleSubmit = async (e) => {
    

    e.preventDefault();
    const landlordId = localStorage.getItem("userId");
    const { status, ...postDataWithoutStatus } = securityGroupformData;
    const postData = {
      ...postDataWithoutStatus,
      status: status ? 1 : 2,
      landlordID: parseInt(landlordId),
    };
    try {
      setloader(true)
      const resData = await createSecurityGroup(postData, setsecurityGroupformData);
      if (resData) {
        toastr.success("Security group created successfully!");
        toggleCreateSecurityGroupForm();
        refreshData();
        setloader(false)
        navigate('/admin/staffmanagement', { state: { campaignTab: 2 } });
       
      }
    } catch (error) {
      console.error("Failed to post Security group:", error);
    }
  };

  const [loading, setloading] = useState(true);
  const defaultimg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  


 
  const location = useLocation();
  const isLinkActive = (route: string) => {
    // Check if the current location matches the given route
    return location.pathname === route;
  };

 

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>Create Security group</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={toggleCreateSecurityGroupForm}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <form className="toggle-height" onSubmit={handleSubmit}>
              <div className="pro-create">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Name <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" 
                       id="name"
                       placeholder="name"
                       value={securityGroupformData.name}
                       onChange={handleChange}/>
                    </div>
                 
                  </div>
                
              
                 
                  
                  <div className="col-lg-12">
                    
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        rows={4}
                        defaultValue={""}
                        id="desc"
                        placeholder="description"
                        value={securityGroupformData.desc}
                        onChange={handleChange}
                      />
                    </div>
                  </div>


                  <div className="col-lg-12">
                    
                  
                      <label className="col-form-label">
                      Security Group Status <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                    <input
                      type="text"
                      className="form-control form-cr"
                      placeholder="User Active/Inactive"
                      id="status"
                      value={securityGroupformData.status ? "Active" : "Inactive"}
                      onChange={handleChange}
                    />
                    <span className="input-group-text input-g">
                      <label className="switch">
                        <input
                          type="checkbox"
                          id="status"
                          checked={securityGroupformData.status}
                          onChange={handleChange}
                        />
                        <span className={securityGroupformData.status === true ? "slider" :"sliderinactive"}></span>
                      </label>
                    </span>
                  </div>
                   
                  </div>
                  <div className="col-lg-12">
                    
                  
                      <label className="col-form-label">
                   <span className="text-danger"></span>
                      </label>
                      <div className="submit-button text-end">
                <button type="submit" className="btn btn-primary"
                 disabled={!securityGroupformData.name || !securityGroupformData.desc || loader}>
                  {loader ? (
                      <>
                        {" "}
                        <span className="spinner-border spinner-border-sm mr-1 "></span>
                      </>
                    ) : null}
                  Create Security Group
                </button>
              </div>
                   
                  </div>
                </div>
              </div>
             
            </form>
          </div>
        </div>
     \
    




      
    </>
  );
};
export default AddSecurityGroup
