import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';
import { authRoutes, publicRoutes } from './Routerlink';
import PrivateRoute from '../PrivateRoute';  // Import the PrivateRoute component

const ALLRoutes = () => {
  useEffect(() => {
    
    const pathname = window.location.pathname;
    if (pathname === "/admin") {
      localStorage.clear();
    }
  }, []);

  const HeaderLayout = () => (
    <>
      <Header />
      <Sidebar />
      <Outlet />
    </>
  );

  const AuthPages = () => (
    <>
      <Outlet />
    </>
  );

  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<HeaderLayout />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route> */}
        {/* Add more routes here if you have additional pages */}
        {authRoutes.map((route, idx) => (
          <Route path={route.path} element={<AuthPages />}>
            <Route path={route.path} element={route.element} key={idx} />
          </Route>
        ))}
        {/* Protecting all other routes */}
        <Route path="/" element={<PrivateRoute element={<HeaderLayout />} />}>
          {publicRoutes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </Router>
  );
};

export default ALLRoutes;
