import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import CompaignComplete from "../../core/modals/compaignComplete_modal";
import { compaignData } from "../../json/campaignData";
import { Table } from "react-bootstrap";
import { TableData } from "../../core/data/interface";
import { all_routes } from "../../router/all_routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  setActivityTogglePopupTwo,
  startLoading,
  Loading,
} from "../../redux/Commonslice";
import { useDispatch, useSelector } from "react-redux";
import CollapseHeader from "../../core/common/collapse-header";
import CampaignModal from "../../core/modals/compaignComplete_modal";
import { getAllStaffManagement } from "../../Services/StaffServices";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import {
  AllSecurityGroups,
  ChangeSecurityGroupStatus,
  createSecurityGroup,
} from "../../Services/SecurityGroupServices";
import Chip from "@mui/material/Chip";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import AddStaffRights from "./AddStaffRights";

import JsonTemplate from "../../Services/PermissionsJsonTemplate";
import {
  AddPermissions,
  AssignedPermissions,
  UpdateSecurityGroupPermissions,
} from "../../Services/PermissionsServices";
import AddSecurityGroup from "./AddSecurityGroup";
import SecurityGroupStaff from "./SecurityGroupStaff";

const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background: "#E41F07 !important",
    },
  },
}));

interface SecurityGroupModel {
  securityGroupID: number;
  securityGroupName: string;
}

const SecurityGroups = () => {
  const navigate = useNavigate();
  const [securityGroupformData, setsecurityGroupformData] = useState({
    name: "",
    desc: "",
    status: false,
  });
  const UserID = localStorage.getItem("userId");
  const [SecurityGroupIDForStaff, setSecurityGroupIDForStaff] = useState(null);
  const classes = useStyles();
  const route = all_routes;
  const dispatch = useDispatch();
  const activityToggle = useSelector(
    (state: any) => state?.activityTogglePopup
  );
  const activityToggleTwo = useSelector(
    (state: any) => state?.activityToggleTwo
  );
  const [IsSecurityGroupFormOpen, setIsSecurityGroupFormOpen] = useState(false);
  const [ispermissionpopup, setPermissionpopup] = useState(false);
  const [securityGroupobject, setsecurityGroupobject] =
    useState<SecurityGroupModel>({
      securityGroupID: 0,
      securityGroupName: "",
    });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setsecurityGroupformData({
      ...securityGroupformData,
      [e.target.id]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const landlordId = localStorage.getItem("userId");
    const { status, ...postDataWithoutStatus } = securityGroupformData;
    const postData = {
      ...postDataWithoutStatus,
      status: status ? 1 : 2,
      landlordID: parseInt(landlordId),
    };
    try {
      const resData = await createSecurityGroup(
        postData,
        setsecurityGroupformData
      );
      if (resData) {
        toastr.success("Security group created successfully!");
        toggleCreateSecurityGroupForm();
        getAllUser(pageNumber);
        navigate("/admin/staffmanagement", { state: { campaignTab: 2 } });

      }
    } catch (error) {
      console.error("Failed to post Security group:", error);
    }
  };

  const handleSwitchChange = async (SecurityGroup) => {
    const updatedStatus = SecurityGroup.status === 1 ? 2 : 1;
    const updatedData = formData.data.map((item) =>
      item.securityGroupID === SecurityGroup.securityGroupID
        ? { ...item, status: updatedStatus }
        : item
    );
    setData(updatedData);
    try {
      await ChangeSecurityGroupStatus(SecurityGroup);
      setFormData((prevData) => ({
        ...prevData,
        data: updatedData,
      }));
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const [loading, setloading] = useState(true);
  const defaultimg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const [formData, setFormData] = useState({
    data: [],
    totalCount: null,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState<number>(6);
  const [getData, setData] = useState([]);

  useEffect(() => {
    getAllUser(pageNumber);
  }, [pageNumber]);

  const getAllUser = async (pageNumber) => {
    dispatch(startLoading());
    setloading(true);
    try {
      AllSecurityGroups(UserID, pageNumber, rowNumbers).then((resData) => {
        if (resData) {
          {
            // const resData = await reqData.json();
            setFormData((prevData) => ({ ...prevData, ...resData }));
            const data = resData.data;
            dispatch(Loading());
            setloading(false);
            setData(data);
          }
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(Loading());
      setloading(true);
    }
  };

  const toggleCreateSecurityGroupForm = () => {
    setIsSecurityGroupFormOpen(!IsSecurityGroupFormOpen);
  };
  const permissionpopupfun = (event, SecurityGroup) => {
    event.preventDefault();
  
    setsecurityGroupobject(SecurityGroup);
    setPermissionpopup(!ispermissionpopup);
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
    getAllStaffManagement(UserID, pageNumber, value);
  };
  const location = useLocation();
  const isLinkActive = (route: string) => {
    // Check if the current location matches the given route
    return location.pathname === route;
  };

  const multiSelectOption = [
    { value: "small_business", label: "Small Business" },
    { value: "corporate_companies", label: "Corporate Companies" },
    { value: "urban_apartment", label: "Urban Apartment" },
    { value: "business", label: "Business" },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const staffid = null;
  const tableHeader = [
    "Group Name",
    "User",
    "Members",
    "Group Status",
    "Permissions",
    "Assign Permissions",
  ];

  const chipClasses = {
    0: "btn btn-square btn-outline-primary bg-white",
    1: "btn btn-square btn-outline-secondary bg-white",
    2: "btn btn-square btn-outline-success bg-white",
  };
  

  // staffrights

  const [PermissionData, setPermissionData] = useState(JsonTemplate);
  const [display, Setdisplay] = useState(false);

  const checkassignedpermissions = () => {
    Setdisplay(false);
    setPermissionpopup(!ispermissionpopup);
    setPermissionData(JsonTemplate);
    setsecurityGroupobject({
      securityGroupID: 0,
      securityGroupName: "",
    });
  };
  useEffect(() => {
    const filtered = getData.filter((SecurityGroup) =>
      SecurityGroup.securityGroupName
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchTerm, getData]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card main-card">
            <div className="card-body">
              <div className="search-section">
                <div className="row">
                  <div className="col-md-5 col-sm-4">
                    <div className="form-wrap icon-form">
                      <span className="form-icon">
                        <i className="ti ti-search" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Security Group"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-8">
                    <div className="export-list text-sm-end">
                      <ul>
                        <li>
                          <Link
                            to="#"
                            className="btn btn-primary add-popup"
                            onClick={toggleCreateSecurityGroupForm}
                          >
                            <i className="ti ti-square-rounded-plus" /> Create
                            Security group
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
                <div
                  className="d-flex align-items-center justify-content-center"
                  // height={"350px"}
                >
                  <div className="spinner-border "></div>
                </div>
              ) : (
                <>
                  {!loading && filteredData.length === 0 ? (
                    <div
                      className="card d-flex align-items-center justify-content-center"
                      style={{ padding: "40px 0", border: "none" }}
                    >
                      <div className="card-body">
                        <img
                          src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
                          alt="No data"
                        />
                        <h3 className="text-center">No Data</h3>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="col-sm-12 table-responsive">
                        <Table responsive hover>
                          <thead className="bg-light">
                            <tr>
                              {tableHeader.map((header, index) => (
                                <th
                                  className="p-4"
                                  style={{
                                    backgroundColor: "#fafafa",
                                    position: "relative",
                                  }}
                                  key={index}
                                >
                                  {header}{" "}
                                  {/* <i
                                    className="fa fa-sort"
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      color: "#6F6F6F",
                                    }}
                                    aria-hidden="true"
                                  ></i> */}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData &&
                              filteredData.map((SecurityGroup, index) => (
                                <tr key={index}>
                                  <td
                                  // onClick={() => handleRowClick(SecurityGroup)}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="ms-3">
                                        <p className="staff-txt mb-1 text-capitalize">
                                          {SecurityGroup.securityGroupName}
                                        </p>
                                        <p className="staff-subtext mb-1 text-capitalize">
                                          {SecurityGroup.securityGroupID}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    onClick={() =>
                                      setSecurityGroupIDForStaff(
                                        SecurityGroup.securityGroupID
                                      )
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#StaffInSecurityGroup"
                                  >
                                    <div className=" ps-4 table-action">
                                      <a
                                        className="action-icon"
                                        aria-expanded="true"
                                      >
                                        <p className=" staff-txt ">
                                          {SecurityGroup.user}
                                        </p>
                                      </a>
                                    </div>
                                  </td>
                                  <td>
                                  <div className="profile-images">
          {Array.isArray(SecurityGroup.imageString) &&
            SecurityGroup.imageString.slice(0, 3).map((profile, index) => (
              <div className="profile-image" key={index}>
                <img src={profile} alt={`Profile ${index + 1}`} />
              </div>
            ))}
          {Array.isArray(SecurityGroup.imageString) &&
            SecurityGroup.imageString.length > 3 && (
              <div className="additional-count">
                +{SecurityGroup.imageString.length - 3}
              </div>
            )}
        </div>
                                  </td>

                                  {!staffid ? (
                                    <td>
                                      <span className="input-group-text switchbackground input-g border-0">
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            id="activeUser"
                                            checked={SecurityGroup.status === 1}
                                            onChange={() =>
                                              handleSwitchChange(SecurityGroup)
                                            }
                                            disabled={SecurityGroup.user !== 0}
                                          />
                                          <span
                                            className={
                                              SecurityGroup.user !== 0
                                                ? "sliderdisable"
                                                : SecurityGroup.status === 1
                                                ? "slider"
                                                : "sliderinactive"
                                            }
                                          ></span>
                                        </label>
                                      </span>
                                    </td>
                                  ) : (
                                    <td>
                                      {SecurityGroup.status === 1 ? (
                                        <span className="staff-active">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="staff-In-active">
                                          Inactive
                                        </span>
                                      )}
                                    </td>
                                  )}
                                  <td>
                                    {SecurityGroup.permissions.length !== 0 ? (
                                      <>
                                        {SecurityGroup.permissions
                                          .slice(0, 3)
                                          .map((permission, index) => {
                                            const chipClass = `${chipClasses[index] || ""} chip-margin`;

                                            return (
                                              <Chip
                                                key={index}
                                                label={permission}
                                                className={chipClass}
                                              />
                                            );
                                          })}
                                        {SecurityGroup.permissions.length >
                                          3 && (
                                          <Chip
                                            key="additional"
                                            label={`+${
                                              SecurityGroup.permissions.length -
                                              3
                                            }`}
                                            className="btn btn-square btn-outline-info btn-outline bg-white"
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <p>N/A</p>
                                    )}
                                  </td>

                                  {!staffid ? (
                                    <td>
                                      <button
                                        type="button"
                                        className="ps-5 btn btn-link btn-rounded btn-sm fw-bold"
                                        data-mdb-ripple-color="dark"
                                      >
                                        <div className="d-flex gap-4">
                                          {SecurityGroup.permissions.length !==
                                          0 ? (
                                            <img
                                              data-toggle="tooltip"
                                              data-placement="right"
                                              title="Update Permissions"
                                              src="https://d38tibxba17kdz.cloudfront.net/StaffManagementImages/editPermissions.svg"
                                              // onClick={() => handleClick(SecurityGroup)}
                                              onClick={(e) =>
                                                permissionpopupfun(
                                                  e,
                                                  SecurityGroup
                                                )
                                              }
                                            />
                                          ) : (
                                            <img
                                              data-toggle="tooltip"
                                              data-placement="right"
                                              title="Assign Permissions"
                                              src="https://d38tibxba17kdz.cloudfront.net/StaffManagementImages/assignPermissions.svg"
                                              // onClick={() => handleClick(SecurityGroup)}
                                              onClick={(e) =>
                                                permissionpopupfun(
                                                  e,
                                                  SecurityGroup
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </button>
                                    </td>
                                  ) : null}
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Stack spacing={2} mt={3}>
                          <Pagination
                            classes={{ ul: classes.ul }}
                            count={Math.ceil(
                              parseInt(formData.totalCount, 10) /
                                parseInt(rowNumbers.toString(), 10)
                            )}
                            page={pageNumber}
                            onChange={handlePageChange}
                          />
                          <div className="text-center">{`${pageNumber} – ${
                            pageNumber * rowNumbers
                          } of ${formData.totalCount} Staff available`}</div>
                        </Stack>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          IsSecurityGroupFormOpen
            ? "toggle-popup sidebar-popup"
            : "toggle-popup"
        }
      >
        <AddSecurityGroup
          toggleCreateSecurityGroupForm={toggleCreateSecurityGroupForm}
          refreshData={() => getAllUser(pageNumber)}
        />
      </div>

      <div
        className={
          ispermissionpopup ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={checkassignedpermissions}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create"></div>
            {/* <AddStaffRights securityGroupobject={securityGroupobject} /> */}

            {securityGroupobject && (
              <AddStaffRights
                securityGroupobject={securityGroupobject}
                ispermissionpopup={ispermissionpopup}
              />
            )}
            {/* <div className="submit-button text-end">
                <Link to="#" className="btn btn-light sidebar-close">
                  Cancel
                </Link>
                <button type="submit" className="btn btn-primary">
                  Create
                </button>
              </div> */}
          </div>
        </div>
      </div>

      <SecurityGroupStaff staffObject={SecurityGroupIDForStaff} />
    </>
  );
};
export default SecurityGroups;
