import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { getAllStaffManagement } from "../../Services/StaffServices";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import { AllSecurityGroups, AssignSecurityGrouptostaff, ChangeSecurityGroupStatus } from "../../Services/SecurityGroupServices";
import Chip from "@mui/material/Chip";
import toastr from "toastr";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "toastr/build/toastr.min.css";

const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background: "#E41F07 !important",
    },
  },
}));

const SecurityGroupModal = ({ staffObject, getAllStaffManagementapi }) => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [previousSelectedRow, setPreviousSelectedRow] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({ data: [], totalCount: null });
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState<number>(6);
  const [data, setData] = useState([]);
  const [dialogData, setDialogData] = useState(null);
  

  const classes = useStyles();
  const UserID = localStorage.getItem("userId");
  const defaultimg = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  useEffect(() => {
    if (staffObject && staffObject.securityGroupID !== undefined) {
      setSelectedRow(staffObject.securityGroupID);
      setPreviousSelectedRow(staffObject.securityGroupID);
    }
  }, [staffObject]);

  useEffect(() => {
    getAllUser(pageNumber);
  }, [pageNumber]);

  const getAllUser = async (pageNumber) => {
    setLoading(true);
    try {
      const resData = await AllSecurityGroups(UserID, pageNumber, rowNumbers);
      if (resData) {
        setFormData(prevData => ({ ...prevData, ...resData }));
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(true);
    }
  };

  const handleSwitchChange = async (SecurityGroup) => {
    const updatedStatus = SecurityGroup.status === 1 ? 2 : 1;
    const updatedData = formData.data.map(item =>
      item.securityGroupID === SecurityGroup.securityGroupID
        ? { ...item, status: updatedStatus }
        : item
    );
    setData(updatedData);
    try {
      await ChangeSecurityGroupStatus(SecurityGroup);
      setFormData(prevData => ({
        ...prevData,
        data: updatedData
      }));
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
    getAllStaffManagement(UserID, pageNumber, value);
  };

  // const handleRadioChange = (event, securityGroup) => {
  //   if (securityGroup.status == 2) {
  //     toastr.info("To assign this, first activate this security group.");
  //   } else if (securityGroup.permissions.length === 0) {
  //     toastr.info("You need to assign permissions to this Security Group.");
  //   } else {
  //     setPreviousSelectedRow(selectedRow);
  //     setSelectedRow(securityGroup.securityGroupID);
  //   }
  // };

  // const handleClose = async (confirmed, SecurityGroup,event) => {
  //   if (confirmed) {
  //     const UserID = localStorage.getItem("userId");
  //     await AssignSecurityGrouptostaff(staffObject, SecurityGroup, UserID);
  //     toastr.success("Security group assigned successfully!");
 
  //     setSelectedRow(SecurityGroup.securityGroupID)
  //   } else {
  //     setSelectedRow(previousSelectedRow);
  //   }
  //   await getAllUser(pageNumber);
  //   event.stopPropagation();
  // };
  const handleRadioChange = (event, securityGroup) => {
    
    if (securityGroup.status == 2) {
      toastr.info(" To assign this First Active this Security group  ");
    }
    else if (securityGroup.permissions.length == 0){
      toastr.info("You need to assign permissions to this Security Group")
    }
     else {
      setPreviousSelectedRow(selectedRow);
      setSelectedRow(securityGroup.securityGroupID);
      setDialogData({ ...securityGroup, staffObject });
      setOpen(true);
    }
  };

  const handleClose = (confirmed,SecurityGroup) => {
    if (confirmed) {
      
      const UserID = localStorage.getItem("userId");
      AssignSecurityGrouptostaff(staffObject, SecurityGroup, UserID);
      toastr.success("security group assigned successfully!");
      setPreviousSelectedRow(selectedRow);
      getAllStaffManagementapi(1);
    } else {
      setSelectedRow(previousSelectedRow);
    }
    setOpen(false);
  };


  const handleDropdownToggle = (event, SecurityGroup) => {
    if (SecurityGroup.status === 2 || SecurityGroup.permissions.length === 0) {
      event.preventDefault();
      return; // Prevent dropdown from opening if conditions are not met
    }
  };

  const tableHeader = ["", "Group Name", "User", "Group Status", "Permissions"];

  return (
    <>
      <div className="row">
        <div>
          {staffObject ? (
            <>
              <div className="d-flex align-items-center py-4">
                <img
                  src={staffObject.documentPath || defaultimg}
                  alt=""
                  style={{ width: "45px", height: "45px" }}
                  className="rounded-circle"
                />
                <div className="ms-3">
                  <h3>{staffObject.name}</h3>
                  <p className="staff-txt mb-1 text-capitalize">{staffObject.role}</p>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="col-md-12">
          <div className="card main-card">
            <div className="card-body">
              <div className="col-sm-12 table-responsive">
                <Table responsive hover>
                  <thead className="bg-light">
                    <tr>
                      {tableHeader.map((header, index) => (
                        <th
                          className="p-4"
                          style={{
                            backgroundColor: "#fafafa",
                            position: "relative",
                          }}
                          key={index}
                        >
                          {header}
                          <i
                            className="fa fa-sort"
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              color: "#6F6F6F",
                            }}
                            aria-hidden="true"
                          ></i>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formData.data && formData.data.map((SecurityGroup, index) => (
                      <tr key={index}>
                        <td>
                          <div className="dropdown table-action">
                            
                              <input
                                type="radio"
                                checked={
                                  selectedRow === SecurityGroup.securityGroupID
                                }
                                onChange={(event) =>
                                  handleRadioChange(event, SecurityGroup)
                                }
                              />
                          
                         
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="ms-3">
                              <p className="staff-txt mb-1 text-capitalize">
                                {SecurityGroup.securityGroupName}
                              </p>
                              <p className="staff-subtext mb-1 text-capitalize">
                                {SecurityGroup.securityGroupID}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <p className="staff-txt mb-1">
                            {SecurityGroup.user}
                          </p>
                        </td>
                        <td>
                          {SecurityGroup.status === 1 ? (
                            <span className="badge badge-pill badge-status bg-success">
                              Active
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-status bg-danger">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          {SecurityGroup.permissions.length !== 0 ? (
                            <>
                              {SecurityGroup.permissions.slice(0, 3).map((permission, index) => (
                                <Chip
                                  key={index}
                                  label={permission}
                                  style={{
                                    backgroundColor:
                                      index === 0 ? "#F5F6F7" :
                                      index === 1 ? "#F7E8EF" :
                                      index === 2 ? "#F5F6F7" : "black",
                                    color:
                                      index === 0 ? "#091E42" :
                                      index === 1 ? "#6A1039" :
                                      index === 2 ? "#147AD6" : "black",
                                  }}
                                />
                              ))}
                              {SecurityGroup.permissions.length > 3 && (
                                <Chip
                                  key="additional"
                                  label={`+${SecurityGroup.permissions.length - 3}`}
                                  style={{ backgroundColor: "#F5F6F7" }}
                                />
                              )}
                            </>
                          ) : (
                            <p>N/A</p>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-center">
                <Stack spacing={2} mt={3}>
                  <Pagination
                    classes={{ ul: classes.ul }}
                    count={Math.ceil(parseInt(formData.totalCount, 10) / parseInt(rowNumbers.toString(), 10))}
                    page={pageNumber}
                    onChange={handlePageChange}
                  />
                  <div className="text-center">{`${pageNumber} – ${pageNumber * rowNumbers} of ${formData.totalCount} Staff available`}</div>
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={() => handleClose(false, null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div className="text-center">
            <img src="https://d38tibxba17kdz.cloudfront.net/StaffManagementImages/Check.svg" />
          </div>
          <div className="text-center">{"Confirmation"}</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogData && (
              <>{"Are you sure you want to assign this security group?"}</>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type="submit"
            className="btn btn-secondary px-5 border"
            onClick={() => handleClose(false, null)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary px-5"
            onClick={() => handleClose(true, dialogData)}
          >
            Add
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SecurityGroupModal;
