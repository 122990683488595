import { useState, useEffect, useRef } from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import Select from "react-select";
import CampaignModal from "../../core/modals/compaignComplete_modal";
import { useDispatch, useSelector } from "react-redux";
import "../../Style/AddStaffRights.css";
import {
  setActivityTogglePopup,
  setActivityTogglePopupTwo,
  startLoading,
  Loading
} from "../../redux/Commonslice";
import { all_routes } from "../../router/all_routes";
import { Table } from "react-bootstrap";
import { getAllStaffManagement } from "../../Services/StaffServices";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import AddStaff from "./AddStaff";
import EditStaff from "./EditStaff";
import {
  createLandlordStaff,
  getAllRoles,
  imageUpload,
  GetstaffDetailById,
  UpdateLandlordStaff,
  SearchByPhoneNumber
} from "../../Services/StaffServices";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
const useStyles = makeStyles(() => ({
  ul: {
    "& .Mui-selected": {
      color: "#fff",
      background: "#E41F07 !important",
    },
  },
}));

const Staff = ({refreshData}) => {
  const UserID = localStorage.getItem("userId");


  const navigate = useNavigate();
  const classes = useStyles();
  const route = all_routes;
  const dispatch = useDispatch();
  const activityToggle = useSelector(
    (state: any) => state?.activityTogglePopup
  );
  const activityToggleTwo = useSelector(
    (state: any) => state?.activityToggleTwo
  );
  const [isAddCampaignFormOpen, setIsAddCampaignFormOpen] = useState(false);
  const [selectedSecurityGroupID, setSelectedSecurityGroupID] = useState({});
  const [loading, setloading] = useState(true);
  const defaultimgs =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const [formData, setFormData] = useState({
    data: [],
    totalCount: null,
  });
  const [StaffformData, setStaffFormData] = useState({
    username: "",
    id_number: "",
    cell_number: "",
    role: "",
    activeUser: 0,
    status: 0,
    imageUrls: "",
});
  const [ParamstaffId,setParamstaffId]=useState(null); 
  const [pageNumber, setPageNumber] = useState(1);
  const [rowNumbers, setRowNumbers] = useState<number>(6);
  const [getData, setData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [defaultimg, setdefaultimg] = useState(null);
  const [defaultimg1, setdefaultimg1] = useState(" https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
 
  const [searchstaff, setsearchstaff] = useState([]);
  const [ProfileImage, setProfileImage] = useState(null);
  const [IdentityImage, setIdentityImage] =useState(null);
  const [UserId, setUserId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [loader, setloader] = useState(false);
  const [idImageUrl, setIdImageUrl] = useState(null);
  const [IdentityImageError, setIdentityImageError] = useState("");
  const [ProfileImageError, setProfileImageError] = useState("");
  const [isProfileImageFilled, setIsProfileImageFilled] = useState(false);
const [isIdentityImageFilled, setIsIdentityImageFilled] = useState(false);
const [isAddStaffForm ,setIsAddStaffForm]=useState(false);
const [closeStaffForm ,setCloseStaffForm]=useState(false);
const [isEditStaffForm ,setIsEditStaffForm]=useState(false);
const [searchTerm, setSearchTerm] = useState("");
const [filteredData, setFilteredData] = useState([]);
const togglecloseform =()=>{
 
  setStaffFormData({
    username: "",
    id_number: "",
    cell_number: "",
    role: "",
    activeUser: 0,
    status: 0,
    imageUrls: "",
  })
  setIsDisabled({
    username: false,
    id_number: false,
    cell_number: false,
    role: false,
    activeUsers: false
});
  setSearchTerm("");
  setIdImageUrl(null);
  setIdentityImage(null)
  setIsAddStaffForm(!isAddStaffForm);
  setIsEditStaffForm(false)
}
  const anotherFunction = (data1) => {
    dispatch(setActivityTogglePopupTwo(!activityToggleTwo));
  
  };
  const toggleAddCampaignForm = () => {
    setIsAddCampaignFormOpen(!activityToggleTwo);
  };
  const resetForm = () => {
    setStaffFormData({
      username: "",
      id_number: "",
      cell_number: "",
      role: "",
      activeUser: 0,
      status: 0,
      imageUrls: "",
    })
    setSearchTerm("");
  };
    const toggleAddStaff = () => {
      setIsAddStaffForm(!isAddStaffForm);
      setParamstaffId(null);
      setIdImageUrl(null);
      setdefaultimg(null);
      setIdentityImage(null);
      setFileName("");
      setSearchQuery("");
      setStaffFormData({
        username: "",
        id_number: "",
        cell_number: "",
        role: "",
        activeUser: 0,
        status: 0,
        imageUrls: "",
      })
  };
  // useEffect(() => {
        const UpdateStaffDatabyId = (ParamstaffId) => {
        
            GetstaffDetailById(ParamstaffId).then((staffResponse) => {
              
    
                if (staffResponse.status == 200) {
    
                    const staffDetailResponse = staffResponse.data
                    setStaffFormData({
                        ...StaffformData,
                        username: staffDetailResponse.name,
                        cell_number: staffDetailResponse.contact,
                        id_number: staffDetailResponse.cnic,
                        role: staffDetailResponse.roleTypeID,
                        activeUser: staffDetailResponse.status,
                    });
                    setdefaultimg(staffDetailResponse.profileImage);
                    setIdImageUrl(staffDetailResponse.identityImage);
                }
    
            });
        }
    // }, []);
    const toggleEditStaffForm = (staffId) => {
      setIsEditStaffForm(!isEditStaffForm)
      setParamstaffId(staffId)
   
      UpdateStaffDatabyId(staffId)
      setIsAddStaffForm(!isAddStaffForm);
    };
  const handlePageChange = (event, value) => {

    setPageNumber(value);
    getAllStaffManagementapi(value);
    
  };
  const location = useLocation();
  const isLinkActive = (route: string) => {
    return location.pathname === route;
  };



  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const tableHeader = ["Name", "Role", "SecurityGroup", "Status", "Actions"];


  useEffect(() => {
    getAllStaffManagementapi(pageNumber);

   
  }, []);

  const getAllStaffManagementapi = async (pageNumber) => { 
   await getAllStaffManagement(UserID, pageNumber, rowNumbers).then((resData) => {
      dispatch(startLoading());
      if (resData) {
       
        {
          setFormData((prevData) => ({ ...prevData, ...resData }));
          const data = resData.data;
          dispatch(Loading());
          setloading(false);
          setData(data);
            setFilteredData(data);
        }
      }
    });
  }


  const fileRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState({
      username: false,
      id_number: false,
      cell_number: false,
      role: false,
      activeUsers: false
  });

  const UploadUserDocument = (UploadImage, userID, mediaTypeID) => {
    

      return {
          status: 1,
          document: Array.from(UploadImage?.document),
          docName: UploadImage.docName,
          userID: userID,
          mediaTypeID: mediaTypeID,
      };
     
  };

  const UpdateStaffData = () => {

      const { username, cell_number, id_number } = StaffformData;
      const landlordId = localStorage.getItem("userId");

      const userDetails = [
          {
              status: 1,
              userTypeID: 7,
          },
      ];

      const StaffData = {
          ...StaffformData,
          id: ParamstaffId,
          name: username,
          contact: cell_number,
          landlordId: parseInt(landlordId),
          userDetails,
          cnic: id_number,
          roleTypeID: StaffformData.role,
          status: StaffformData.activeUser,

      };
      return StaffData;
  };
  const preparePostData = () => {
      const password = "123456";

      const { username, cell_number, id_number, } = StaffformData;
      const email = `${username}@gmail.com`;

      const landlordId = localStorage.getItem("userId");

      const userDetails = [
          {
              status: 1,
              userTypeID: 7,
          },
      ];

      const postData = {
          ...StaffformData,
          name: username,
          contact: cell_number,
          email,
          password,
          landlordId: parseInt(landlordId),
          userDetails,
          cnic: id_number,
          roleTypeID: StaffformData.role,
          status: 8,
      };
      return postData;
  };
  const customStyles = {
      option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isFocused ? "#E41F07" : "#fff",
          color: state.isFocused ? "#fff" : "#000",
          "&:hover": {
              backgroundColor: "#E41F07",
          },
      }),
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = async (e) => {
    
    setSearchQuery(e.target.value)
   
      try {
          const response = await SearchByPhoneNumber(e);
          if (response.status == 200) {

              const data = response.data;
             
              setsearchstaff(data);
              const val = data.find((staff) => staff.contact === e.target.value);
              setSearchQuery(e.target.value)
              if (val) {
                setStaffFormData({
                      ...StaffformData,
                      username: val.name,
                      cell_number: val.contact,
                      id_number: val.cnic,
                      role: val.roleTypeID,
                      activeUser: val.status,
                  });
                  setdefaultimg(val.profileImage);
                  if(val.profileImage){
                    setIsProfileImageFilled(true);
                  }
                  
                  if(val.identityImage){
                    setIsIdentityImageFilled(true);
                  }
                  setIdentityImage(val.identityImage)
                  setUserId(val.id);
                  setIsDisabled({
                      username: !!val.name,
                      id_number: !!val.cnic,
                      cell_number: !!val.contact,
                      role: !!val.roleTypeID,
                      activeUsers: !!val.status
                  });
              } else {
                  setdefaultimg(defaultimg);
                  setIdentityImage(IdentityImage);
                  setStaffFormData({
                      username: "",
                      id_number: "",
                      cell_number: e.target.value,
                      role: "",
                      activeUser: 0,
                      status: 0,
                      imageUrls: "",
                  });
                  setIsDisabled({
                      username: false,
                      id_number: false,
                      cell_number: false,
                      role: false,
                      activeUsers: false
                  });
              }
          } else {
              console.error("Failed to fetch search results");
          }
      } catch (error) {
          console.error("Error searching staff:", error);
      }
  };
  useEffect(() => {
    getAllRoles().then((resData) => {
        if (resData) {
            {
                setRoles(resData);
            }
        }
    });
  
}, []);
const ResponseData = async (resData) => {

  
    if (resData.statusCode === 200) {
        const res = resData.data;

     
        const id = res.id;

        if (id) {
            const Profileimage = UploadUserDocument(ProfileImage, id, 5);
            const Identityimage = UploadUserDocument(IdentityImage, id, 9);
            await Promise.all([imageUpload(Profileimage), imageUpload(Identityimage)]);
            setIdentityImage(null);
                setdefaultimg(null);
        }
        getAllStaffManagementapi(pageNumber)
        setloader(false);
        toastr.success('Staff added successfully!');
   
    } else if (resData.statusCode === 201) {
      toastr.error('Staff already exists!');
        setStaffFormData({
            username: '',
            id_number: '',
            cell_number: '',
            role: '',
            activeUser: 0,
            status: 0,
            imageUrls: '',
        });
        setloader(false);
        setProfileImage(null);
        setdefaultimg(null);
    } else if (resData.statusCode === 501) {
        toastr.error(resData.message);
        setStaffFormData({
            username: '',
            id_number: '',
            cell_number: '',
            role: '',
            activeUser: 0,
            status: 0,
            imageUrls: '',
        });
        setloader(false);
        setProfileImage(null);
        setdefaultimg(null);
    }
};

const handleSubmit = async (e) => {
    e.preventDefault();
    // Update Staff
    if (ParamstaffId) {
        try {
          
            const StaffDataUpdate = UpdateStaffData();
            setloader(true);
            const resData = await UpdateLandlordStaff(StaffDataUpdate);
            if (resData.status === 200) {
                try {
                    let UpdateProfileimage = null;
                    let UpdateIdentityimage = null;

                    if (ProfileImage?.document !== undefined) {
                        UpdateProfileimage = UploadUserDocument(ProfileImage, ParamstaffId, 5);
                        await imageUpload(UpdateProfileimage);
                        setdefaultimg(null);
                    }

                    if (IdentityImage !== null && IdentityImage.document !== undefined) {
                        UpdateIdentityimage = UploadUserDocument(IdentityImage, ParamstaffId, 9);
                        await imageUpload(UpdateIdentityimage);
                        setIdentityImage(null);
                    
                    }
                } catch (error) {
                    console.error('Failed to upload images:', error);
                }
                toastr.success('Staff Updated successfully!');
                // toggleAddCampaignForm();
                setStaffFormData({
                    username: '',
                    id_number: '',
                    cell_number: '',
                    role: '',
                    activeUser: 0,
                    status: 0,
                    imageUrls: '',
                });
                getAllStaffManagementapi(pageNumber);
                setloader(false);

            
            }
        } catch (error) {
            console.error('Failed to update staff data:', error);
        }
    } else {
        const postData = preparePostData();
        try {
            setloader(true);
            const resData = null;
            await createLandlordStaff(postData, setFormData).then((resData) => {
                ResponseData(resData);
            });
            await Promise.all([
              refreshData(),
            
          ]);
         
            setIsAddStaffForm(!isAddStaffForm)
        } catch (error) {
            console.error('Failed to post staff data:', error);
        }
    }
};

const handleChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if (e.target.id === "activeUser") {
        let status;
        if (value) {
            if (StaffformData.activeUser === 12) {
                status = 8;
            } else {
                status = StaffformData.status === 8 ? 8 : 1;
            }
        } else {
            status = 2;
        }

        setStaffFormData({ ...StaffformData, activeUser: status, status });
    } else {
        setStaffFormData({ ...StaffformData, [e.target.id]: value });
    }
};
const handleFileUpload = (MediaTypeId, e) => {
  
    const file = e.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
        if (MediaTypeId === 5) {
            setProfileImageError("File size should not exceed 2MB");

        } else if (MediaTypeId === 9) {
            setIdentityImageError("File size should not exceed 2MB");
        }
        e.target.value = "";
        setTimeout(() => {
          if(MediaTypeId === 5){
            setProfileImageError("");
          }
          else 
            setIdentityImageError("");
        }, 3000);
        return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
        const result = event.target.result;
        if (typeof result === 'string') {
           
        } else {
            const byteArray = new Uint8Array(result);
            const image = {
                document: Array.from(byteArray),
                docName: file.name,

            };
            if (MediaTypeId === 5) {
                const imageUrl = URL.createObjectURL(file);
                setdefaultimg(imageUrl);
                setProfileImage(image);
                setIsProfileImageFilled(true);
            } else if (MediaTypeId === 9) {
              const imageUrl = URL.createObjectURL(file);
              setIdImageUrl(imageUrl);
                setIdentityImage(image);
                setIsIdentityImageFilled(true);
                setFileName(file.name);
            }
        }
    };
    reader.readAsArrayBuffer(file);
};

const statusMapping = {
  1: "Active",
  2: "Inactive",
  8: "Pending",
  12: "Rejected",
};
useEffect(() => {
  const filtered = getData.filter((StaffformData) => {
    const statusText = statusMapping[StaffformData.status] || "";
    return (
      StaffformData.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      statusText.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  setFilteredData(filtered);
}, [searchTerm, getData]);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card main-card">
            <div className="card-body">
              <div className="search-section">
                <div className="row">
                  <div className="col-md-5 col-sm-4">
                    <div className="form-wrap icon-form">
                      <span className="form-icon">
                        <i className="ti ti-search" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search Staff"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-8">
                    <div className="export-list text-sm-end">
                      <ul>
                        
                        <li>
                          <Link
                            to="#"
                            className="btn btn-primary add-popup"
                            onClick={toggleAddStaff}
                          >
                            <i className="ti ti-square-rounded-plus" /> Add staff
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {loading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              // height={"350px"}
            >
              <div className="spinner-border "></div>
            </div>
          ):(
            <>
{!loading && filteredData.length === 0 ? (
     <div
     className="card d-flex align-items-center justify-content-center"
     style={{ padding: "40px 0", border: "none" }}
   >
     <div className="card-body">
       <img
         src="https://d2og5lryw1ajtt.cloudfront.net/icons/NoData.svg"
         alt="No data"
       />
       <h3 className="text-center">No Data</h3>
     </div>
   </div>
 ) : (
  <div>
                  <div className="col-sm-12 table-responsive">
                <Table responsive hover>
                  <thead className="bg-light">
                    <tr>
                      {tableHeader.map((header, index) => (
                        <th
                          className="p-4"
                          style={{
                            backgroundColor: "#fafafa",
                            position: "relative",
                          }}
                          key={index}
                        >
                          {header}{" "}
                          {/* <i
                            className="fa fa-sort"
                            style={{
                              position: "absolute",
                              right: "5px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              color: "#6F6F6F",
                            }}
                            aria-hidden="true"
                          ></i> */}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((staff, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={staff.documentPath || defaultimg1}
                              alt=""
                              style={{ width: "45px", height: "45px" }}
                              className="rounded-circle"
                            />
                            <div className="ms-3">
                              <p className="staff-txt mb-1 text-capitalize">
                                {staff.name}
                              </p>
                              <p className="staff-subtext mb-1 text-capitalize">
                                +{staff.contact}
                              </p>
                            </div>
                          </div>
                        </td>
                        <td className="">{staff.desc}</td>
                        <td className="ps-5">
                          {staff.securityGroupID ? (
                            <>
                              <p className=" text-capitalize">
                                {staff.securityGroupName}
                              </p>
                              <p className=" text-capitalize">
                                {staff.securityGroupID}
                              </p>
                            </>
                          ) : (
                            <p>N/A</p>
                          )}
                        </td>

                        <td className="">
                          {staff.status === 1 && (
                            <span className="badge badge-pill badge-status bg-success">
                              Active
                            </span>
                          )}
                          {staff.status === 8 && (
                            <span className="badge badge-pill badge-status bg-warning">
                              Pending
                            </span>
                          )}
                          {staff.status === 2 && (
                            <span className="badge badge-pill badge-status bg-danger">
                              Inactive
                            </span>
                          )}
                          {staff.status === 12 && (
                            <span className="badge badge-pill badge-status bg-info">
                              Rejected
                            </span>
                          )}
                          {/* {staff.status === 'Running' && <span className="badge badge-pill badge-status bg-green">{staff.status}</span>} */}
                        </td>

                        <td className="">
                          {/* <div className="dropdown">
                                                        <button className="btn btn-outline-secondary dropdown-toggle" type="button" id={`dropdownMenuButton${index}`} data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="ti ti-more"></i>
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton${index}`}>
                                                            <li><a className="dropdown-item" href="#">Edit</a></li>
                                                            <li><a className="dropdown-item" href="#">Delete</a></li>
                                                        </ul>
                                                    </div> */}
                          <div className=" ps-4 table-action">
                            <Link
                              to="#"
                              className="action-icon"
                              data-bs-toggle="dropdown"
                              aria-expanded="true"
                            >
                              <i className="fa fa-ellipsis-v"></i>
                            </Link>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              style={{
                                position: "absolute",
                                inset: "0px auto auto 0px",
                                margin: "0px",
                                transform:
                                  "translate3d(-99.3333px, 35.3333px, 0px)",
                              }}
                              data-popper-placement="bottom-start"
                            >
                              <Link
                                className="dropdown-item edit-popup"
                                to="#"
                                // onClick={toggleAddCampaignForm}
                                onClick={() => toggleEditStaffForm(staff.landlordStaffId)}
                                // onClick={() =>
                                //   dispatch(
                                //     setActivityTogglePopupTwo({
                                //       activityTogglePopupTwo: !activityToggleTwo,
                                //       staffId: staff.landlordStaffId, // Pass the staff ID here
                                //     })
                                //   )
                                // }
                               
                              >
                          
                                <i className="ti ti-edit text-blue"></i> Edit
                              </Link>
                              <Link
                                className="dropdown-item"
                                to="#"
                                onClick={() => setSelectedSecurityGroupID(staff)}
                                data-bs-toggle="modal"
                                data-bs-target="#AssignSecurityGroup"
                              >
                                <i className="ti ti-user text-success"></i>{" "}
                                Assign Security Group
                              </Link>
                            </div>
                          </div>
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-center">
              {formData.totalCount?(
                <Stack spacing={2} mt={3}>
                  <Pagination
                    classes={{ ul: classes.ul }}
                    count={Math.ceil(
                      parseInt(formData.totalCount, 10) /
                        parseInt(rowNumbers.toString(), 10)
                    )}
                    page={pageNumber}
                    onChange={handlePageChange}
                  />
                  <div className="text-center">{`${pageNumber} – ${
                    pageNumber * rowNumbers
                  } of ${formData.totalCount} Staff available`}</div>
                </Stack>
                ):(
                  <div>
                  <span className="spinner-border spinner-border-sm mr-1 "></span></div>
                )}
              </div>
              </div>

               )}
       </> )}
            </div>
          </div>
        </div>
      </div>
    
     
      <div
        className={
        isEditStaffForm ||  isAddStaffForm ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        
      <AddStaff
       toggleAddCampaignForm={toggleAddCampaignForm}
       togglecloseform={togglecloseform}
       ParamstaffId={ParamstaffId}
       handleChange={handleChange}
       handleSubmit={handleSubmit}
       handleSearch={handleSearch}
       searchQuery={searchQuery}
       handleFileUpload={handleFileUpload}
       IdentityImageError={IdentityImageError}
       IdentityImage={IdentityImage}
       idImageUrl={idImageUrl}
       StaffformData={StaffformData}
       isDisabled ={isDisabled}
       ProfileImageError={ProfileImageError}
       fileRef={fileRef}
       fileName={fileName}
       isProfileImageFilled={isProfileImageFilled}
       defaultimg={defaultimg}
       toggleEditStaffForm={toggleEditStaffForm}
       isIdentityImageFilled={isIdentityImageFilled}
       roles={roles}
       loader={loader}
       resetForm={resetForm}


      />
      </div>
      {/* /Add New Campaign */}

      {/* /Page Wrapper */}
      <CampaignModal staffObject={selectedSecurityGroupID} getAllStaffManagementapi= {getAllStaffManagementapi}/>
    </>
  );
};

export default Staff;

