import api from "../Interceptor";


export const UserProfileImage = async () => {
  try {
    
  
    const landlordId = localStorage.getItem("userId");
    const response = await api.get(
      `/Users/GetUserImage?userID=${landlordId}`
    );
   
    if (response.status === 200) {
      
      
    } else {
      console.error("Failed to get Profile Image:", response.statusText);
    }
    return response.data;
  } catch (error) {
    console.error("Error getting Profile Image:", error);
  }
};

export const userlogin = async (formData, ) => {
  try {
    const response = await api.post("/Users/Authenticate", formData);
    return response.data;
  } catch (error) {
    console.error("invaid user:", error);
    throw error;
  }
};
