import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_USER_BASE_URL,
});

api.interceptors.request.use(
  function (config) {
    debugger
    const token = localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
      
    }
   config.headers["platform"] = "web";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default api;
