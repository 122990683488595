// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-secondary-th {
  background-color: #bfdfe2 !important;
}
.bg-light {
  background-color: #f2f9f9 !important;
}
.headerAdd {
  background-color: #ffeec6 !important;
}
.headerview {
  background-color: #e0f8e3 !important;
}
.headerApprove {
  background-color: #b0b0b0 !important;
}

.loading-spinners {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #ba393b;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: rotate 1s linear infinite !important;
}
`, "",{"version":3,"sources":["webpack://./src/Style/AddStaffRights.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,+CAA+C;AACjD","sourcesContent":[".bg-secondary-th {\n  background-color: #bfdfe2 !important;\n}\n.bg-light {\n  background-color: #f2f9f9 !important;\n}\n.headerAdd {\n  background-color: #ffeec6 !important;\n}\n.headerview {\n  background-color: #e0f8e3 !important;\n}\n.headerApprove {\n  background-color: #b0b0b0 !important;\n}\n\n.loading-spinners {\n  border: 4px solid rgba(0, 0, 0, 0.3);\n  border-top: 4px solid #ba393b;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  animation: rotate 1s linear infinite !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
