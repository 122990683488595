/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { all_routes } from '../../router/all_routes';
import CampaignModal from '../../core/modals/compaignComplete_modal';
import CollapseHeader from '../../core/common/collapse-header';

import Staff from './staff';
import SecurityGroups from './securityGroups';
import { PMSDashBoardApi } from '../../Services/DashboardServices';

const Staffmanagement = () => {
    const location = useLocation();
    const route = all_routes;
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedTab, setSelectedTab] = useState(1);
    const handleTabClick = (tab) => setSelectedTab(tab);
   


    useEffect(() => {
        
        if (location.state) {
            const campaignTab = location.state.campaignTab || 1;
            setSelectedTab(campaignTab);
     
        }
    }, [location.state]);

    const isLinkActive = (route) => location.pathname === route;

    useEffect(() => {
        PMSDashBoard();
        
    }, []);
    const PMSDashBoard = async () => {
        setLoading(true);
        try {
            const reqData = await PMSDashBoardApi();
            const resData = reqData.data;
            setData(resData.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    // Extract Staff and Security Group data from the response
    const staffData = data.find(item => item.name === "Staff");
    const securityGroupData = data.find(item => item.name === "Security Group");

    return (
        <>
            <div className="page-wrapper">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-header">
                                <div className="row align-items-center">
                                    <div className="col-4">
                                        <h4 className="page-title">
                                            Staff <span className="count-title">Management</span>
                                        </h4>
                                    </div>
                                    <div className="col-8 text-end">
                                        <div className="head-icons">
                                            {/* <CollapseHeader /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <>
                                        {staffData && (
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="campaign-box bg-success-light">
                                                    <div className="campaign-img">
                                                        <span>
                                                            <img src="https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/Customers.svg" />
                                                        </span>
                                                        <p>{staffData.name}</p>
                                                    </div>
                                                    <h2>{staffData.totalCount}</h2>
                                                </div>
                                            </div>
                                        )}
                                        {securityGroupData && (
                                            <div className="col-xl-6 col-lg-6">
                                                <div className="campaign-box bg-warning-light">
                                                    <div className="campaign-img">
                                                        <span>
                                                            <img src="https://d2og5lryw1ajtt.cloudfront.net/StaffManagement/Dashboard/S.securitygroup.svg" />
                                                        </span>
                                                        <p>{securityGroupData.name}</p>
                                                    </div>
                                                    <h2>{securityGroupData.totalCount}</h2>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}


                            </div>
                            <div className="campaign-tab">
                                <ul className="nav">
                                    <li>
                                        <a  className={selectedTab==1 ? "active" : ""} onClick={() => handleTabClick(1)}>
                                            Staff <span>{staffData ? staffData.totalCount : 0}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a  className={selectedTab==2 ? "active" : ""} onClick={() => handleTabClick(2)}>
                                            Security group <span>{securityGroupData ? securityGroupData.totalCount :0}</span>
                                        </a>
                                    </li>
                                 
                                </ul>
                            </div>
                            {selectedTab === 1 && <Staff   refreshData={PMSDashBoard}/>}
                            {selectedTab === 2 && <SecurityGroups/>}
                           
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Staffmanagement;
